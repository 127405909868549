import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from "js-cookie";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: () => import("../pages/MainDashboard.vue"),
    meta: {
      parentName: "dashboard",
      requiresAuth: true,
    },
  },
  {
    path: "/detail/:id",
    name: "detail",
    component: () => import("../pages/DetailItem.vue"),
    meta: {
      parentName: "dashboard",
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../pages/LoginPage.vue"),
    meta: {
      parentName: "login",
    },
  },
  {
    path: "/room",
    name: "room",
    component: () => import("../pages/RoomPage.vue"),
    meta: {
      parentName: "room",
      requiresAuth: true,
    },
  },
  {
    path: "/price-list",
    name: "price-list",
    component: () => import("../pages/PriceList.vue"),
    meta: {
      parentName: "price-list",
      requiresAuth: true,
    },
  },
  {
    path: "/pk-calculator",
    name: "pk-calculator",
    component: () => import("../pages/PKCalculatorPage.vue"),
    meta: {
      parentName: "pk-calculator",
      requiresAuth: true,
    },
  },
  {
    path: "/room/:id",
    name: "type",
    component: () => import("../pages/TypePage.vue"),
    meta: {
      parentName: "room",
      requiresAuth: true,
    },
  },
  {
    path: "/config",
    name: "config",
    component: () => import("../pages/ConfigPage.vue"),
    meta: {
      parentName: "config",
      requiresAuth: true,
    },
  },
  {
    path: "/plan",
    name: "plan",
    component: () => import("../pages/PlanPage.vue"),
    meta: {
      parentName: "plan",
      requiresAuth: true,
    },
  },
  {
    path: "/cart",
    name: "cart",
    component: () => import("../pages/CartItem.vue"),
    meta: {
      parentName: "dashboard",
      requiresAuth: true,
    },
  },
  {
    path: "/account",
    name: "account",
    component: () => import("../pages/AccountPage.vue"),
    meta: {
      parentName: "account",
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  let user = {};
  const dimensiAuth = await Cookies.get("dimensiAuth");

  if (dimensiAuth) {
    user = JSON.parse(dimensiAuth);
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!dimensiAuth) {
      return next({ name: "login" });
    }
  }

  if (to.name === "login" && dimensiAuth) {
    return next({ name: "dashboard" });
  }

  if (
    (to.meta.parentName === "account" || to.meta.parentName === "master") &&
    user.role !== "admin"
  ) {
    return next({ name: "login" });
  }

  return next();
});

export default router;
